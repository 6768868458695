import React from "react";
import { ContentCard, Pricing, TopPage } from "../../components";

const Home = () => {
  return (
    <div>
      <TopPage />
      <Pricing />
      <ContentCard />
    </div>
  );
};

export default Home;
