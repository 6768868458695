import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTravel } from "../../redux/reducer";
import { filteredPrice } from "../../utils/filteredPrice";
import "./styles.css";

const Pricing = () => {
  const dispatch = useDispatch();
  const { itineraries } = useSelector((state) => state.reducer);
  const navigate = useNavigate();
  const onSelectItinerary = (itinerary) => {
    let body = {
      isFixePrice: true,
      backAndForth: false,
      forth: {
        origin: itinerary.origin,
        originLatLng: itinerary.originLatLng,
        destination: itinerary.destination,
        destinationLatLng: itinerary.destinationLatLng,
        date: "",
        person: 1,
        luggage: 0,
        distance: itinerary.distance,
        distanceValue: itinerary.distanceValue,
        duration: itinerary.duration,
        prices: itinerary.prices,
        price: null,
      },
      back: {
        origin: itinerary.destination,
        originLatLng: itinerary.destinationLatLng,
        destination: itinerary.origin,
        destinationLatLng: itinerary.originLatLng,
        distance: itinerary.distance,
        duration: itinerary.duration,
        distanceValue: itinerary.distanceValue,
        price: null,
      },
    };
    dispatch(addTravel(body));
    navigate("/reservation");
  };
  return (
    <div className="pricing_container">
      <h2>Prix des courses en taxi VTC à Gare de Chantilly - Gouvieux</h2>
      <div className="pricing_table">
        <div className="pricing_table_header">
          <Row>
            <Col span={12}>
              <div className="pricing_table_cell">Prix course en taxi VTC</div>
            </Col>
            <Col span={6}>
              <div className="pricing_table_cell">Prix minimum</div>
            </Col>
            <Col span={6}>
              <div className="pricing_table_cell">Prix moyen</div>
            </Col>
          </Row>
        </div>
        {itineraries.map((itinerary, i) => (
          <Row
            className="travel_item"
            key={itinerary._id}
            style={{ backgroundColor: i % 2 === 0 ? "" : "#ebeff8" }}
            onClick={() => onSelectItinerary(itinerary)}
          >
            <Col span={12}>
              <div className="pricing_table_cell">
                {itinerary.originName} à {itinerary.destinationName}
              </div>
            </Col>
            <Col span={6}>
              <div className="pricing_table_cell">
                {filteredPrice(itinerary.prices.confort.day)}
              </div>
            </Col>
            <Col span={6}>
              <div className="pricing_table_cell">
                {filteredPrice(
                  itinerary.prices.confort.day +
                    (itinerary.prices.van.weNight -
                      itinerary.prices.confort.day) /
                      2
                )}
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
