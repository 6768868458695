import { Col, Row } from "antd";
import React from "react";
import { cardList } from "./cardList";
import "./styles.css";

const ContentCard = () => {
  return (
    <div className="card_content_container">
      {cardList.map((card, i) =>
        i % 2 === 0 ? (
          <div
            key={card._id}
            className={i === cardList.length - 1 ? "" : "card_container"}
          >
            <Row gutter={30}>
              <Col md={12} xs={24}>
                <div className="card_content_image">
                  <img src={card.image} alt={card.title} loading="lazy" />
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div>
                  <h2>{card.title}</h2>
                  <p>{card.text}</p>
                  {card.list && (
                    <ul>
                      {card.list.map((lt, j) => (
                        <li key={j}>{lt}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div
            key={card._id}
            className={i === cardList.length - 1 ? "" : "card_container"}
          >
            <Row gutter={30} className="reverse-column">
              <Col md={12} xs={24}>
                <div>
                  <h2>{card.title}</h2>
                  <p>{card.text}</p>
                  {card.list && (
                    <ul>
                      {card.list.map((lt, j) => (
                        <li key={j}>{lt}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="card_content_image">
                  <img src={card.image} alt={card.title} loading="lazy" />
                </div>
              </Col>
            </Row>
          </div>
        )
      )}
    </div>
  );
};

export default ContentCard;
