import { Button, Form, Input } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const InformationsForm = ({ selectedType, onFinish }) => {
  const { forth, back, backAndForth, isFixePrice } = useSelector(
    (state) => state.reducer
  );
  const onReserve = (values) => {
    let body = {
      user: values,
      travel: { forth, back, backAndForth, isFixePrice },
    };
    onFinish(body);
  };
  return (
    <div>
      <h3>Type de véhicule : {selectedType}</h3>
      <Form
        name="basic"
        onFinish={onReserve}
        autoComplete="off"
        layout="vertical"
        style={{ marginTop: 20 }}
      >
        <Form.Item
          label="Nom"
          name="lastname"
          rules={[
            { required: true, message: "Veuillez renseigner votre nom!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Prénom"
          name="firstname"
          rules={[
            { required: true, message: "Veuillez renseigner votre prénom!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Numéro de téléphone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner votre numéro de téléphone!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/*<Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner votre adresse mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>*/}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="selectButton"
            style={{ marginTop: 10 }}
          >
            Réserver
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InformationsForm;
