import { Col, Row } from "antd";
import React from "react";

const BackInformations = ({ back }) => {
  return (
    <div className="map_informations_content">
      <h2 className="map_title">Transfert Retour</h2>
      <div className="map_informations_text_content">
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Lieu de départ :
          </Col>
          <Col className="map_informations_text_bold" style={{ flex: 1 }}>
            {back.origin}
          </Col>
        </Row>
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Lieu d'arrivée :
          </Col>
          <Col className="map_informations_text_bold" style={{ flex: 1 }}>
            {back.destination}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BackInformations;
