import React from "react";
import TravelForm from "../../components/travel-form/TravelForm";

const Reserver = () => {
  return (
    <div>
      <TravelForm />
    </div>
  );
};

export default Reserver;
