import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BackInformations,
  ForthInformations,
  InformationsForm,
  MyGoogleMap,
  OtherInformations,
  Prices,
} from "../../components";
import { newReservation } from "../../redux/actions";
import { setFixPrices } from "../../redux/reducer";
import "./styles.css";

const Reservation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { forth, back, backAndForth, isFixePrice } = useSelector(
    (state) => state.reducer
  );
  const [selectedType, setSelectedType] = useState("");

  const onFinish = (values) => {
    let body = {
      ...values,
      travel: {
        ...values.travel,
        type: selectedType,
      },
    };
    if (isFixePrice) {
      body = {
        ...body,
        travel: {
          ...body.travel,
          price: backAndForth
            ? values.travel.forth.price[selectedType.toLowerCase()] * 2
            : values.travel.forth.price[selectedType.toLowerCase()],
        },
      };
    } else {
      body = {
        ...body,
        travel: {
          ...body.travel,
          price: backAndForth
            ? (
                parseFloat(
                  values.travel.forth.price[selectedType.toLowerCase()]
                ) +
                parseFloat(values.travel.back.price[selectedType.toLowerCase()])
              ).toFixed(2)
            : values.travel.forth.price[selectedType.toLowerCase()],
        },
      };
      console.log(body);
    }
    newReservation(body, dispatch);
  };

  useEffect(() => {
    if (!forth.origin) {
      navigate("/");
    }
  }, [forth.origin, navigate]);

  useEffect(() => {
    if (isFixePrice && forth.date) {
      const dayStartTime = 6 * 60;
      const dayEndTime = 20 * 60;
      const forthDate = new Date(forth.date);
      const forthDay = forthDate.getDay();
      const forthHours = forthDate.getHours();
      const forthMinutes = forthDate.getMinutes();
      const forthActiveTime = forthHours * 60 + forthMinutes;
      let forthIsNight = true;
      let forthIsWeekend = false;
      if (forthDay === 6 || forthDay === 0) {
        forthIsWeekend = true;
      }
      if (dayStartTime <= forthActiveTime && forthActiveTime < dayEndTime) {
        forthIsNight = false;
      }
      let forthPrice = {
        confort: 0,
        business: 0,
        van: 0,
      };
      if (!forthIsNight && !forthIsWeekend) {
        forthPrice = {
          confort:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.confort.day).toFixed(2),
          business:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.business.day).toFixed(2),
          van: parseFloat(forth.prices.van.day).toFixed(2),
        };
      }
      if (forthIsNight && !forthIsWeekend) {
        forthPrice = {
          confort:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.confort.night).toFixed(2),
          business:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.business.night).toFixed(2),
          van: parseFloat(forth.prices.van.night).toFixed(2),
        };
      }
      if (!forthIsNight && forthIsWeekend) {
        forthPrice = {
          confort:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.confort.weDay).toFixed(2),
          business:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.business.weDay).toFixed(2),
          van: parseFloat(forth.prices.van.weDay).toFixed(2),
        };
      }
      if (forthIsNight && forthIsWeekend) {
        forthPrice = {
          confort:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.confort.weNight).toFixed(2),
          business:
            forth.person > 4 || forth.luggage > 3
              ? null
              : parseFloat(forth.prices.business.weNight).toFixed(2),
          van: parseFloat(forth.prices.van.weNight).toFixed(2),
        };
      }
      dispatch(setFixPrices(forthPrice));
      setSelectedType("");
    } // eslint-disable-next-line
  }, [forth.date, forth.person, forth.luggage, backAndForth, dispatch]);

  return (
    <div className="reservation_container">
      <div className="map_container">
        <div className="map_informations_container">
          <div className="map_informations">
            <ForthInformations
              forth={forth}
              backAndForth={backAndForth}
              isFixePrice={isFixePrice}
            />
            {backAndForth && <BackInformations back={back} />}
            <OtherInformations forth={forth} isFixePrice={isFixePrice} />
          </div>
          {forth.price && <Prices setSelectedType={setSelectedType} />}
        </div>
        <div className="map_card_container">
          <MyGoogleMap travel={forth} />
          {backAndForth && <MyGoogleMap travel={back} />}
          <div
            className={
              selectedType ? "map_form_content" : "map_form_content_none"
            }
          >
            <InformationsForm selectedType={selectedType} onFinish={onFinish} />
          </div>
        </div>
      </div>
      <div
        className={
          selectedType ? "map_form_content_bottom" : "map_form_content_none"
        }
      >
        <InformationsForm selectedType={selectedType} onFinish={onFinish} />
      </div>
    </div>
  );
};

export default Reservation;
