import { Button, Col, DatePicker, Row } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { changeBackAndForth, changeDate } from "../../redux/reducer";

const ForthInformations = ({ forth, backAndForth, isFixePrice }) => {
  const dispatch = useDispatch();
  const onChangeDate = (value) => {
    dispatch(changeDate(new Date(value) + ""));
  };
  const onChangeBackAndForth = (value) => {
    dispatch(changeBackAndForth(value));
  };
  return (
    <div className="map_informations_content">
      <h2 className="map_title">Transfert Aller</h2>
      <div className="map_informations_text_content">
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Lieu de départ :
          </Col>
          <Col className="map_informations_text_bold" style={{ flex: 1 }}>
            {forth.origin}
          </Col>
        </Row>
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Lieu d'arrivée :
          </Col>
          <Col className="map_informations_text_bold" style={{ flex: 1 }}>
            {forth.destination}
          </Col>
        </Row>
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Date :
          </Col>
          <Col className="reservation-date" style={{ flex: 1 }}>
            {isFixePrice ? (
              <DatePicker
                onChange={onChangeDate}
                format="DD/MM/YYYY HH:mm"
                showTime={true}
                placeholder="Date et heure de départ"
                className="travel_from_container_input"
                minDate={dayjs()}
                initialValues={dayjs()}
                suffixIcon={<FaCalendarAlt />}
              />
            ) : (
              new Date(forth.date).toLocaleString("fr")
            )}
          </Col>
        </Row>
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Type de transfert :
          </Col>
          {isFixePrice ? (
            <div>
              <Button
                type={backAndForth ? "default" : "primary"}
                onClick={() => onChangeBackAndForth(false)}
              >
                Aller simple
              </Button>
              <Button
                type={backAndForth ? "primary" : "default"}
                onClick={() => onChangeBackAndForth(true)}
              >
                Aller-Retour
              </Button>
            </div>
          ) : (
            <Col className="map_informations_text_bold" style={{ flex: 1 }}>
              {backAndForth ? "Aller-Retour" : "Aller simple"}
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ForthInformations;
