import { Col, InputNumber, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { setLuggage, setPerson } from "../../redux/reducer";

const OtherInformations = ({ forth, isFixePrice }) => {
  const dispatch = useDispatch();
  return (
    <div className="map_informations_content">
      <h2 className="map_title">Informations complémentaires</h2>
      <div className="map_informations_text_content">
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Nombre de personne :
          </Col>
          <Col className="map_informations_text_bold">
            {isFixePrice ? (
              <InputNumber
                min={1}
                max={6}
                value={forth.person}
                onChange={(e) => dispatch(setPerson(e))}
              />
            ) : (
              forth.person
            )}
          </Col>
        </Row>
        <Row className="mb15">
          <Col className="map_informations_text" style={{ width: 190 }}>
            Nombre de bagage :
          </Col>
          <Col className="map_informations_text_bold">
            {isFixePrice ? (
              <InputNumber
                min={0}
                max={6}
                value={forth.luggage}
                onChange={(e) => dispatch(setLuggage(e))}
              />
            ) : (
              forth.luggage
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OtherInformations;
