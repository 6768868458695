import axios from "axios";
import { toast } from "react-toastify";
import {
  addTravel,
  clearTravel,
  getItinerariesFailed,
  getItinerariesRequest,
  getItinerariesSuccess,
} from "./reducer";
const api = process.env.REACT_APP_API;
export const getOriginDistance = (body) => {
  return axios.post(`${api}/api/get-origin-distance`, body);
};
export const getDistance = (body, goToReservation, dispatch) => {
  axios
    .post(`${api}/api/get-distance`, body)
    .then((res) => {
      dispatch(addTravel(res.data));
      goToReservation();
    })
    .catch((err) => console.log(err));
};

export const getitineraries = (dispatch) => {
  dispatch(getItinerariesRequest());
  axios
    .get(`${api}/api/itineraries`)
    .then((res) => {
      dispatch(getItinerariesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getItinerariesFailed());
    });
};

export const newReservation = (body, dispatch) => {
  axios
    .post(`${api}/api/reservations`, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      dispatch(clearTravel());
    })
    .catch((err) => toast(err.response.data.message, { type: "error" }));
};