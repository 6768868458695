import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Header = () => {
  return (
    <header>
      <div className="header">
        <Link to="/">
          <img
            className="header_logo"
            src={require("../../images/vtc-elite-transparent.webp")}
            alt="VTC"
            loading="lazy"
          />
        </Link>
        <nav className="header_nav">
          <Link to="/reserver">Réserver un taxi</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
