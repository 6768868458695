import { GoogleMap, MarkerF } from "@react-google-maps/api";
import React from "react";
import { filteredTime } from "../../utils/filteredTime";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const MyGoogleMap = ({ travel }) => {
  return (
    <div className="map_content">
      <div>
        <GoogleMap
          options={{ streetViewControl: false, zoomControl: true }}
          mapContainerStyle={containerStyle}
          onLoad={(map) => {
            const bounds = new window.google.maps.LatLngBounds();
            const locations = [travel.originLatLng, travel.destinationLatLng];
            locations.forEach((location) => {
              bounds.extend({
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lng),
              });
            });
            map.fitBounds(bounds);
          }}
        >
          <>
            <MarkerF
              position={{
                lng: travel.originLatLng.lng,
                lat: travel.originLatLng.lat,
              }}
            />
            <MarkerF
              position={{
                lng: travel.destinationLatLng.lng,
                lat: travel.destinationLatLng.lat,
              }}
            />
          </>
        </GoogleMap>
        <div className="duration_informations">
          <span>Durée: {filteredTime(travel.duration / 60)}</span>
          <span>Distance: {travel.distance}</span>
        </div>
      </div>
    </div>
  );
};

export default MyGoogleMap;
