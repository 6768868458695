import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Footer, Header } from "../layout";
import { Home, Reservation, Reserver } from "../pages";
import { getitineraries } from "../redux/actions";

const Navigation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getitineraries(dispatch);
  }, [dispatch]);
  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/reservation" element={<Reservation />} />
          <Route exact path="/reserver" element={<Reserver />} />
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default Navigation;
