import { createSlice } from "@reduxjs/toolkit";

export const reducer = createSlice({
  name: "reducer",
  initialState: {
    itineraries: [],
    isFixePrice: false,
    itinerariesLoading: false,
    backAndForth: false,
    forth: {
      origin: "",
      originLatLng: "",
      destination: "",
      destinationLatLng: "",
      date: "",
      person: 1,
      luggage: 0,
      distance: "",
      forthDistanceValue: null,
      duration: "",
      price: null,
    },
    back: {
      origin: "",
      originLatLng: "",
      destination: "",
      destinationLatLng: "",
      distance: "",
      backDistanceValue: null,
      duration: "",
      price: null,
    },
  },
  reducers: {
    addTravel: (state, action) => {
      state.isFixePrice = action.payload.isFixePrice;
      state.forth = action.payload.forth;
      state.backAndForth = action.payload.backAndForth;
      state.back = action.payload.back;
    },
    changeBackAndForth: (state, action) => {
      state.backAndForth = action.payload;
    },
    setForthAddress: (state, action) => {
      state.forth = { ...state.forth, ...action.payload };
    },
    setBackAddress: (state, action) => {
      state.back = { ...state.back, ...action.payload };
    },
    changeDate: (state, action) => {
      state.forth.date = action.payload;
    },
    setFixPrices: (state, action) => {
      state.forth.price = action.payload;
    },
    setPerson: (state, action) => {
      state.forth.person = action.payload;
    },
    setLuggage: (state, action) => {
      state.forth.luggage = action.payload;
    },
    getItinerariesRequest: (state) => {
      state.itinerariesLoading = true;
    },
    getItinerariesSuccess: (state, action) => {
      state.itinerariesLoading = false;
      state.itineraries = action.payload;
    },
    getItinerariesFailed: (state) => {
      state.itinerariesLoading = false;
    },
    clearTravel: (state) => {
      state.isFixePrice = false;
      state.backAndForth = false;
      state.forth = {
        origin: "",
        originLatLng: "",
        destination: "",
        destinationLatLng: "",
        date: "",
        person: 1,
        luggage: 0,
        distance: "",
        duration: "",
        price: null,
      };
      state.back = {
        origin: "",
        originLatLng: "",
        destination: "",
        destinationLatLng: "",
        distance: "",
        duration: "",
        price: null,
      };
    },
  },
});

export const {
  addTravel,
  changeBackAndForth,
  setForthAddress,
  setBackAddress,
  changeDate,
  setFixPrices,
  setPerson,
  setLuggage,
  getItinerariesRequest,
  getItinerariesSuccess,
  getItinerariesFailed,
  clearTravel,
} = reducer.actions;

export default reducer.reducer;
