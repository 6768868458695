export const cardList = [
  {
    _id: 1,
    title: "Prix compétitif",
    text: "Profitez de tarifs imbattables en comparant plus d’un millier de services de taxis et VTC locaux. Réservez dès maintenant et bénéficiez d’un prix fixe avantageux, garanti pour chaque trajet. Trouvez facilement une option économique pour vous déplacer en toute sérénité !",
    image: require("../../images/mobile-price.webp"),
  },
  {
    _id: 2,
    title: "Paiement sécurisé",
    text: "Réglez en toute tranquillité grâce à notre système de paiement sécurisé en ligne. Votre trajet est prépayé, garantissant une prestation conforme à vos attentes. Le paiement au chauffeur ne s’effectue qu’après la course, et en cas de problème, un remboursement est prévu. Nous acceptons Visa et Mastercard pour une transaction sans souci.",
    image: require("../../images/mobile-payment.webp"),
  },
  {
    _id: 3,
    title: "Chauffeurs qualifiés",
    text: "Voyagez en toute confiance avec nos chauffeurs expérimentés, disponibles 24/7 pour vos trajets, qu’ils soient courts ou longs. Choisissez parmi des chauffeurs multilingues (anglais, espagnol et plus encore), maîtrisant parfaitement la région. Discrétion, fiabilité et professionnalisme sont leurs maîtres mots.",
    image: require("../../images/driver.webp"),
  },
  {
    _id: 4,
    title: "Véhicules adaptés",
    text: "Quelles que soient vos exigences, nos partenaires taxis et VTC disposent d’une large gamme de véhicules pour vous offrir confort et espace. Des berlines de luxe aux minibus de 15 places, en passant par les vans spacieux pour les groupes ou les familles nombreuses, trouvez le véhicule qui vous correspond. Tous sont climatisés et conçus pour transporter confortablement vos bagages.",
    image: require("../../images/cars.webp"),
  },
  {
    _id: 5,
    title: "Plein d’options & services",
    text: "Des petits plus qui font toute la différence : chargeurs de téléphone, bouteilles d’eau, tout est pensé pour votre confort ! Demandez un siège bébé, vérifiez si votre animal de compagnie est le bienvenu, ou organisez une navette privée pour vos événements (séminaires, congrès, etc.). Un accueil personnalisé à l’aéroport ou à la gare avec pancarte peut également être arrangé. La plupart des taxis et VTC mettent à disposition dans leur véhicule un chargeur de téléphone et des bouteilles d’eau. N’hésitez pas à demander :",
    list: [
      "Un réhausseur ou siège bébé pour votre enfant",
      "Si les animaux sont acceptés",
      "D’organiser une navette privée pour vos évènements (séminaires, congrès, pèlerinages etc.)",
      "Un accueil avec pancarte à l’aéroport ou à la gare.",
    ],
    image: require("../../images/car-luxe.webp"),
  },
  {
    _id: 6,
    title: "Votre chauffeur vous attend !",
    text: "Avec nos services de taxi flexibles, votre chauffeur vous attend même si votre train est en retard ! Pas de stress : réservez depuis la Gare de Chantilly - Gouvieux et voyagez l’esprit tranquille.",
    image: require("../../images/waiting-driver.webp"),
  },
  {
    _id: 7,
    title: "Quand arriver à la Gare de Chantilly - Gouvieux ?",
    text: "Pour un voyage sans imprévu, arrivez à la Gare de Chantilly - Gouvieux au moins une heure avant le départ. Préparez-vous sereinement et prenez le temps de tout organiser avant votre trajet.",
    image: require("../../images/station.webp"),
  },
];
