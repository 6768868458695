import React from "react";
import TravelForm from "../travel-form/TravelForm";
import "./styles.css";

const TopPage = () => {
  return (
    <div className="top_page_container">
      <div className="top_page_cover">
        <h1 className="top_page_title">
          Transport Taxi VTC, Votre chauffeur privé dans le secteur de Creil,
          pour des trajets confortables et sans stress, disponibles à tout
          moment.
        </h1>
        <div className="top_page_description">
          Profitez d'un service personnalisé et réservez en toute simplicité
          pour vos déplacements à Creil et ses alentours.
        </div>
        <TravelForm />
      </div>
    </div>
  );
};

export default TopPage;
